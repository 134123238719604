.graduation-header {
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 80px;
  background-image: linear-gradient(180deg, transparent 75%, white 100%),
    url(../../img/Program-page-header.webp);
  background-position: center;
  background-size: cover;

  .header-text-container {
    .top-heading {
      color: white;
      text-shadow: 3px 3px 5px black;
    }

    .sub-heading {
      color: white;
      text-shadow: 3px 3px 5px black;
    }

    .squircle-small-blue {
      background-color: white;
    }
  }
}

.grad-contact-block {
  width: 90%;
  padding: 20px 5px;
  background-color: #002149;
  border-radius: 30px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.466);

  h2 {
    color: white;
    text-shadow: 3px 3px 5px black;
  }
  h3 {
    color: white;
    padding-top: 10px;
  }
  p {
    color: white;
    margin: 5px;
  }

  .inline-link {
    &:hover {
      color: white;
    }
  }
}

@media screen and (max-width: 1100px) {
  .graduation-header {
    background-image: linear-gradient(180deg, transparent 75%, white 100%),
      url(../../img/Program-page-header-mobile.webp);
  }
}
