.graduation-header {
  background-image: linear-gradient(#0000 75%, #fff 100%), url("Program-page-header.d9aff303.webp");
  background-position: center;
  background-size: cover;
  margin: 0 auto;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
}

.graduation-header .header-text-container .top-heading, .graduation-header .header-text-container .sub-heading {
  color: #fff;
  text-shadow: 3px 3px 5px #000;
}

.graduation-header .header-text-container .squircle-small-blue {
  background-color: #fff;
}

.grad-contact-block {
  width: 90%;
  background-color: #002149;
  border-radius: 30px;
  padding: 20px 5px;
  box-shadow: 0 20px 50px #0007;
}

.grad-contact-block h2 {
  color: #fff;
  text-shadow: 3px 3px 5px #000;
}

.grad-contact-block h3 {
  color: #fff;
  padding-top: 10px;
}

.grad-contact-block p {
  color: #fff;
  margin: 5px;
}

.grad-contact-block .inline-link:hover {
  color: #fff;
}

@media screen and (width <= 1100px) {
  .graduation-header {
    background-image: linear-gradient(#0000 75%, #fff 100%), url("Program-page-header-mobile.c53a1acb.webp");
  }
}

/*# sourceMappingURL=commencement-ceremony-info.f2db7cea.css.map */
